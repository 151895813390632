import * as subtypeService from './service/subtype.service';
const state = {
	subtype: null,
	subtypes: [],
	subtypesPagination: {
		page: 1,
		limit: 10,
		totalItems: 0,
		totalPages: 0,
	},
	selectedSubtype: null,
	flagObserveAsset: false,
	flagRequiredAsset:false,
};

const mutations = {
	SET_SUBTYPES(state, payload) {
		state.subtypes = [...payload];
	},
	SET_SUBTYPES_PAGINATION(state, payload) {
		state.subtypesPagination = { ...state.subtypesPagination, ...payload };
	},

	/*x confirmar */
	UPDATE_SUBTYPE(state, updatedSubtype) {
		const index = state.subtypes.findIndex(
			(subtype) => subtype.id === updatedSubtype.id
		);
		if (index !== -1) {
			state.subtypes.splice(index, 1, updatedSubtype);
		}
	},
	DELETE_SUBTYPE(state, subtypeId) {
		const index = state.subtypes.findIndex((subtype) => subtype.id === subtypeId);
		if (index !== -1) {
			state.subtypes.splice(index, 1);
		}
	},
	SET_SELECTED_SUBTYPE(state, payload) {
		state.selectedSubtype = payload;
	},
	SET_FLAG_OBSERVE_ASSET(state, data) {
		state.flagObserveAsset = data;
	},
	SET_FLAG_REQUIRED_ASSET(state, data) {
		state.flagRequiredAsset = data;
	},
};

const actions = {
	async createSubtype(__, data) {
		try {
			const response = await subtypeService.saveSubtype(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async listSubtype({ commit }, data) {
		try {
			const response = await subtypeService.getSubtype(data);
			commit('SET_SUBTYPES', response);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async updateSubtype({ commit }, { id, data }) {
		try {
			const response = await subtypeService.updateSubtype(id, data);
			commit('UPDATE_SUBTYPE', response);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async deleteSubtype(__, id) {
		try {
			await subtypeService.deleteSubtype(id);
			return { ok: true };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	switchFlagObserveAsset({ commit }, value) {
		commit('SET_FLAG_OBSERVE_ASSET', value);
	},

	switchFlagRequiredAsset({commit},value){
		commit('SET_FLAG_REQUIRED_ASSET', value);
	},

	changeSelectedSubtype({ commit }, value) {
		commit('SET_SELECTED_SUBTYPE', value);
	},

	cleanUser({ commit }) {
		commit('SET_USER', null);
	},
};

const getters = {};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
