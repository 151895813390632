import Vue from 'vue';
import VueRouter from 'vue-router';
import DefaultLayout from '../layouts/DefaultLayout.vue';
import DashboardLayout from '../layouts/DashboardLayout';
import isAuthenticatedGuard from '@/router/auth-guard';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		redirect: '/login',
		name: 'ToLogin',
	},
	{
		path: '/',
		component: DefaultLayout,
		name: 'DefaultLayout',
		children: [
			{
				path: '/login',
				name: 'Login',
				component: () =>
					import(/* webpackChunkName: "login" */ '../views/Login.vue'),
			},
			{
				path: '/login/resetpassword',
				name: 'ResetPassword',
				component: () =>
					import(
						/* webpackChunkName: "ResetPassword" */ '../views/account/ResetPassword.vue'
					),
			},
			{
				path: '/recover/:token',
				name: 'RecoverPassword',
				component: () =>
					import(
						/* webpackChunkName: "RecoverPassword" */ '../views/account/RecoverPassword.vue'
					),
			},
		],
	},
	{
		path: '/',
		component: DashboardLayout,
		name: 'Home',
		children: [
			{
				path: '/dashboard',
				name: 'Dashboard',
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
			},

			{
				path: '/dashboard/user/permissions',
				name: 'UserPermissions',
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "UserPermissions" */ '@/views/dashboard/user/permissions/permissions.vue'
					),
			},
			{
				path: '/dashboard/user/password',
				name: 'UserChangePassword',
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "UserChangePassword" */ '@/views/dashboard/user/password/changePassword.vue'
					),
			},
			{
				path: '/permissions',
				name: 'HomePermissions',
				meta: { moduleName: 'permisosAplicacion', pageName: 'listaArea' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "PermissionsPage" */ '@/views/dashboard/permissions/index.vue'
					),
			},
			{
				path: '/dashboard/inventory',
				name: 'InventoryHome',
				beforeEnter: isAuthenticatedGuard,
				meta: { moduleName: 'inventario', pageName: 'listaActivos' },
				component: () =>
					import(
						/* webpackChunkName: "ContractHome" */ '@/views/dashboard/inventory/home/InventoryHomeView.vue'
					),
			},
			{
				path: '/dashboard/permissions',
				name: 'PermissionsHome',
				meta: { moduleName: 'permisosAplicacion', pageName: 'homeConfig' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "ContractHome" */ '@/views/dashboard/permissions/home/PermissionsHomeView.vue'
					),
			},
			{
				path: '/dashboard/incidents',
				name: 'IncidentsHome',
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "ContractHome" */ '@/views/dashboard/incident/home/IncidentHomeView.vue'
					),
			},
			{
				path: '/permissions/config',
				name: 'ConfigPermissions',
				meta: { moduleName: 'permisosAplicacion', pageName: 'homeConfig' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "PermissionsAreaPage" */ '@/views/dashboard/permissions/configuration/ConfigurationHomeView.vue'
					),
			},
			//
			{
				path: '/permissions/config/asset-subtype',
				name: 'ConfigAssetSubtypePermissions',
				meta: { moduleName: 'permisosAplicacion', pageName: 'listaTipoActivos' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "PermissionsAreaPage" */ '@/views/dashboard/permissions/configuration/assetSubtype/index.vue'
					),
			},
			{
				path: '/permissions/config/spare-subtype',
				name: 'ConfigSpareSubtypePermissions',
				meta: { moduleName: 'permisosAplicacion', pageName: 'listaTipoRepuestos' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "PermissionsAreaPage" */ '@/views/dashboard/permissions/configuration/spareSubtype/index.vue'
					),
			},
			{
				path: '/permissions/config/qualification-criteria',
				name: 'ConfigQualificationCriteriaPermissions',
				meta: { moduleName: 'permisosAplicacion', pageName: 'listaCriteriosCalificaciones' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "PermissionsAreaPage" */ '@/views/dashboard/permissions/configuration/qualificationCriteria/index.vue'
					),
			},
			//
			{
				path: '/permissions/areas',
				name: 'AreasPermissions',
				meta: { moduleName: 'permisosAplicacion', pageName: 'listaArea' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "PermissionsAreaPage" */ '@/views/dashboard/permissions/areas/index.vue'
					),
			},
			{
				path: '/permissions/classifications',
				name: 'ClassificationsPermissions',
				meta: {
					moduleName: 'permisosAplicacion',
					pageName: 'listaClasificacion',
				},
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "PermissionsClassificationPage" */ '@/views/dashboard/permissions/classifications/index.vue'
					),
			},
			{
				path: '/permissions/subtypes',
				name: 'SubtypesPermissions',
				meta: { moduleName: 'permisosAplicacion', pageName: 'listaSubTipos' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "SubtypesPermissions" */ '@/views/dashboard/permissions/subtypes/index.vue'
					),
			},
			{
				path: '/inventory/assets',
				name: 'AssetsPermissions',
				meta: { moduleName: 'inventario', pageName: 'listaActivos' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "PermissionsSubTypesPage" */ '@/views/dashboard/inventory/assets/index.vue'
					),
			},
			{
				path: '/inventory/assets/create',
				name: 'AssetsCreate',
				meta: { moduleName: 'inventario', pageName: 'creacionActivo' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "AssetsCreate" */ '@/views/dashboard/inventory/assets/create/index.vue'
					),
			},
			{
				path: '/inventory/assets/edit/:assetId',
				name: 'AssetsEdit',
				meta: { moduleName: 'inventario', pageName: 'editarActivo' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "AssetsEdit" */ '@/views/dashboard/inventory/assets/update/index.vue'
					),
			},
			{
				path: '/inventory/assets/assign-assets',
				name: 'AssignUserAssets',
				// meta: { moduleName: 'inventario', pageName: 'listaActivos' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "DetailActiveInventory" */ '@/views/dashboard/inventory/assets/assign/index.vue'
					),
			},
			{
				path: '/inventory/assets/return-assets',
				name: 'ReturnAssets',
				// meta: { moduleName: 'inventario', pageName: 'listaActivos' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "DetailActiveInventory" */ '@/views/dashboard/inventory/assets/return/index.vue'
					),
			},
			{
				path: '/inventory/assets/detail-asset/:assetId',
				name: 'DetailActiveInventory',
				meta: { moduleName: 'inventario', pageName: 'detalleActivo' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "DetailActiveInventory" */ '@/views/dashboard/inventory/assets/detail/index.vue'
					),
			},
			{
				path: '/inventory/assets/confirm-assignment/:assetAssignId',
				name: 'ConfirmAssignmentInventory',
				//meta: { moduleName: 'inventario', pageName: 'confirmacionAsignacionActivo' },
				meta: { moduleName: 'Activos', pageName: 'confirmacionAsignacionActivo' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "DetailActiveInventory" */ '@/views/dashboard/inventory/assets/confirm-assignment/index.vue'
					),
			},
			{
				path: '/inventory/assets/devolution-asset/:assetAssignId',
				name: 'DevolutionAssetInventory',
				//meta: { moduleName: 'inventario', pageName: 'confirmacionDevolucionActivo' },
				meta: { moduleName: 'Activos', pageName: 'confirmacionDevolucionActivo' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "DetailActiveInventory" */ '@/views/dashboard/inventory/assets/devolution/index.vue'
					),
			},
			{
				path: '/inventory/assets/detail-asset/:assetId/detail-maintenance/:assetMaintenanceId',
				name: 'DetailMaintenanceInventory',
				meta: { moduleName: 'inventario', pageName: 'mantenimientoActivo' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "DetailActiveInventory" */ '@/views/dashboard/inventory/assets/detail-maintenance/index.vue'
					)
			},
			{
				path: '/inventory/assets/detail-asset/:assetId/detail-assignment/:assetAssignId',
				name: 'AssignmentAssetDetail',
				meta: { moduleName: 'inventario', pageName: 'asignacionActivo' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "DetailActiveInventory" */ '@/views/dashboard/inventory/assets/detail-assignment/index.vue'
					),
			},
			{
				path: '/inventory/assets/detail-asset/:assetId/detail-assignment/:assetAssignId/detail-return/:assetAssignReturnId',
				name: 'ReturnAssetDetail',
				meta: { moduleName: 'inventario', pageName: 'devolucionActivo' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "DetailActiveInventory" */ '@/views/dashboard/inventory/assets/detail-return/index.vue'
					),
			},
			{
				path: '/return/list',
				name: 'AssetReturnsList',
				meta: { moduleName: 'devoluciones', pageName: 'listaDevoluciones' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "AssetReturnsList" */ '@/views/dashboard/AssetReturns/list/index.vue'
					),
			},
			{
				path: '/return/detail/:returnId',
				name: 'AssetReturnsDetail',
				meta: { moduleName: 'devoluciones', pageName: 'detalleDevolucion' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "AssetReturnsDetail" */ '@/views/dashboard/AssetReturns/detail/index.vue'
					),
			},
			{
				path: '/inventory/transfers',
				name: 'TransfersPermissions',
				//meta: { moduleName: 'inventario', pageName: 'listaActivos' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "PermissionsSubTypesPage" */ '@/views/dashboard/inventory/transfers/index.vue'
					),
			},
			{
				path: '/inventory/transfer/list-transfer',
				name: 'ListTransfer',
				//meta: { moduleName: 'permisosAplicacion', pageName: 'listaArea' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "PermissionsAreaPage" */ '@/views/dashboard/inventory/transfers/index.vue'
					),
			},
			{
				path: '/inventory/transfer/list-reception',
				name: 'ListReception',
				//meta: { moduleName: 'permisosAplicacion', pageName: 'listaArea' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "PermissionsAreaPage" */ '@/views/dashboard/inventory/receptions/index.vue'
					),
			},
			{
				path: '/inventory/transfers/register',
				name: 'RegisterTransfer',
				//meta: { moduleName: 'inventario', pageName: 'listaActivos' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "PermissionsSubTypesPage" */ '@/views/dashboard/inventory/transfers/registerTransfer/index.vue'
					),
			},
			{
				path: '/inventory/assets/detail-asset/:assetId/detail-transfer/:transferId',
				name: 'DetailTransfer',
				//meta: { moduleName: 'inventario', pageName: 'listaActivos' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "DetailActiveInventory" */ '@/views/dashboard/inventory/transfers/detail/index.vue'
					),
			},
			{
				path: '/inventory/spares',
				name: 'SparesPermissions',
				meta: { moduleName: 'inventario', pageName: 'listaRepuestos' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "PermissionsSubTypesPage" */ '@/views/dashboard/inventory/spares/index.vue'
					),
			},
			{
				path: '/inventory/spares/create',
				name: 'SparesCreate',
				meta: { moduleName: 'inventario', pageName: 'registrarRepuesto' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "SparesCreate" */ '@/views/dashboard/inventory/spares/create/index.vue'
					),
			},
			{
				path: '/inventory/spares/edit/:spareId',
				name: 'SparesEdit',
				meta: { moduleName: 'inventario', pageName: 'editarRepuesto' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "SparesEdit" */ '@/views/dashboard/inventory/spares/edit/index.vue'
					),
			},
			{
				path: '/inventory/spares/detail-spare/:spareId',
				name: 'DetailSpareInventory',
				meta: { moduleName: 'inventario', pageName: 'detalleRepuesto' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "DetailActiveInventory" */ '@/views/dashboard/inventory/spares/detail/index.vue'
					),
			},
			{
				path: '/inventory/spares/detail-spare/:spareId/detail-assignment/:spareAssignmentId',
				name: 'DetailSpareAssignment',
				//meta: { moduleName: 'inventario', pageName: 'detalleRepuesto' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "DetailSpareAssignment" */ '@/views/dashboard/inventory/spares/detail-assignment-spare/index.vue'
					)
			},
			{
				path: '/incident/list',
				name: 'IncidentList',
				meta: { moduleName: 'incidentes', pageName: 'listaIncidentes' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "IncidentList" */ '@/views/dashboard/incident/list/index.vue'
					),
			},
			{
				path: '/incident/create',
				name: 'IncidentCreate',
				meta: { moduleName: 'incidentes', pageName: 'creacionIncidente' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "IncidentCreate" */ '@/views/dashboard/incident/create/index.vue'
					),
			},
			{
				path: '/incident/detail-incident/:incidentId',
				name: 'DetailTicketIncident',
				meta: { moduleName: 'incidentes', pageName: 'detalleIncidente' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "DetailActiveInventory" */ '@/views/dashboard/incident/detailTicket/index.vue'
					),
			},
			{
				path: '/incident/incident-confirm/:incidentId',
				name: 'IncidentConfirm',
				// meta: { moduleName: 'incidentes', pageName: 'detalleIncidente' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "DetailActiveInventory" */ '@/views/dashboard/incident/IncidentConfirm/index.vue'
					),
			},
			{
				path: '/request/list',
				name: 'RequestList',
				meta: { moduleName: 'solicitudes', pageName: 'listaSolicitudes' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "RequestList" */ '@/views/dashboard/request/list/index.vue'
					),
			},
			{
				path: '/request/detail/:requestId',
				name: 'RequestDetail',
				meta: { moduleName: 'solicitudes', pageName: 'detalleSolicitud' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "RequestDetail" */ '@/views/dashboard/request/detail/index.vue'
					),
			},
			{
				path: '/inventory/assets/detail-asset/:assetId/unsubscribe',
				name: 'UnsubscribeAsset',
				// meta: { moduleName: 'inventario', pageName: 'listaActivos' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "DetailActiveInventory" */ '@/views/dashboard/inventory/assets/unsubscribe/index.vue'
					),
			},
			{
				path: '/inventory/assets/multiple-assignments',
				name: 'MultipleAssignmentsList',
				// meta: { moduleName: 'inventario', pageName: 'listaActivos' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "DetailActiveInventory" */ '@/views/dashboard/MultipleAssignments/ListMultipleAssignments/index.vue'
					),
			},
			{
				path: '/inventory/assets/create-multiple-assignments',
				name: 'CreateMultipleAssignments',
				// meta: { moduleName: 'inventario', pageName: 'listaActivos' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "DetailActiveInventory" */ '@/views/dashboard/MultipleAssignments/CreateMultipleAssignments/index.vue'
					),
			},
			{
				path: '/inventory/assets/return-multiple-assignments/:assetAssignmentId',
				name: 'ReturnMultipleAssignments',
				// meta: { moduleName: 'inventario', pageName: 'listaActivos' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "DetailActiveInventory" */ '@/views/dashboard/MultipleAssignments/ReturnMultipleAssignments/index.vue'
					),
			},
			{
				path: '/inventory/assets/detail-multiple-assignments/:assetAssignmentId',
				name: 'DetailMultipleAssignments',
				// meta: { moduleName: 'inventario', pageName: 'listaActivos' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "DetailActiveInventory" */ '@/views/dashboard/MultipleAssignments/DetailMultipleAssignments/index.vue'
					),
			},
			{
				path: '/inventory/assets/detail-multiple-assignments-return/:assetAssignmentReturnId',
				name: 'DetailMultipleAssignmentsReturn',
				// meta: { moduleName: 'inventario', pageName: 'listaActivos' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "DetailActiveInventory" */ '@/views/dashboard/MultipleAssignments/DetailMultipleAssignmentsReturn/index.vue'
					),
			},
			{
				path: '/401',
				name: 'NotAuthorized',
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(/* webpackChunkName: "NotAuthorized" */ '../views/401.vue'),
			},
			{
				path: '/:pathMatch(.*)*',
				name: 'NotFound',
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(/* webpackChunkName: "NotFound" */ '../views/404.vue'),
			},
		],
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;
