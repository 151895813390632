import * as classificationService from "./service/classification.service";
const state = {
  classification: null,
  classifications: [],
  classificationsPagination: {
    page: 1,
    limit: 10,
    totalItems: 0,
    totalPages: 0,
  },
  allClassifications: [],
  loadingClassification: false,
  filtersClassification: {
    area: null,
  },
};

const mutations = {
  SET_FILTER_CLASSIFICATION(state, data) {
    state.filtersClassification = data;
  },

  SET_LOADING_CLASSIFICATION(state, data) {
    state.loadingClassification = data;
  },

  SET_ALL_CLASSIFICATIONS(state, data) {
		state.allClassifications = data;
	},

	SET_CLASSIFICATIONS(state, payload) {
		state.classifications = payload;
	},

	SET_CLASSIFICATIONS_PAGINATION(state, payload) {
		state.classificationsPagination = { ...state.classificationsPagination, ...payload };
	},

  SET_CLEAN_CLASSIFICATIONS(state) {
    state.classifications = [];
    state.classificationsPagination.page = null;
    state.classificationsPagination.limit = null;
    state.classificationsPagination.totalItems = null;
    state.classificationsPagination.totalPages = null;
  },

  SET_PAGE(state, page) {
    state.classifications.page = page;
  },

  SET_LIMIT(state, limit) {
    state.classifications.limit = limit;
  },

  /*x confirmar */
  UPDATE_CLASSIFICATION(state, updatedClassification) {
    const index = state.classifications.findIndex(
      (classification) => classification.id === updatedClassification.id
    );
    if (index !== -1) {
      state.classifications.splice(index, 1, updatedClassification);
    }
  },
  DELETE_CLASSIFICATION(state, classificationId) {
    const index = state.classifications.findIndex(
      (classification) => classification.id === classificationId
    );
    if (index !== -1) {
      state.classifications.splice(index, 1);
    }
  },
  /** */
};

const actions = {
  async createClassification({ state }, data) {
    try {
      const existingClassification = state.classifications.find(
        (classification) =>
          classification.name === data.name &&
          classification.company_id === data.company_id
      );
      if (existingClassification) {
        return {
          ok: false,
          error: "Esta área ya existe para la empresa seleccionada",
        };
      }
      const response = await classificationService.saveClassification(data);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async createClassificationMultiple(__, data) {
		try {
			const response = await classificationService.saveClasificationMultiple(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

  async downloadClassificationFormat() {
		try {
			await classificationService.downloadClasificationFormat();
			return { ok: true };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

  async listAllClassifications({ commit }, data) {
		try {
			const response = await classificationService.getClassification(data);
			commit('SET_ALL_CLASSIFICATIONS', response.data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

  async listClassification({ commit }, data) {
		try {
			commit('SET_CLASSIFICATIONS', []);
			const response = await classificationService.getClassification(data);
			const pagination = {
				page: response?.page,
				limit: response?.limit,
				totalItems: response?.totalItems,
				totalPages: response?.totalPages,
			};
			commit('SET_CLASSIFICATIONS', response?.data || []);
			commit('SET_CLASSIFICATIONS_PAGINATION', pagination || {});
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

  async updateClassification({ state, commit }, { id, data }) {
    try {
      const existingClassification = state.classifications.find(
        (classification) =>
          classification.name === data.name &&
          classification.company_id === data.company_id
      );
      if (existingClassification && existingClassification.id !== id) {
        return {
          ok: false,
          error: "Esta clasificación ya existe para la compañia seleccionada",
        };
      }

      delete data["company_id"];

      const response = await classificationService.updateClassification(id, data);
      commit("UPDATE_CLASSIFICATION", response);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async deleteClassification(__, id) {
		try {
			const response = await classificationService.deleteClassification(id);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},
  /**/

  cleanUser({ commit }) {
    commit("SET_USER", null);
  },

  cleanClassifications({ commit }) {
		commit('SET_CLASSIFICATIONS', []);
		commit('SET_CLASSIFICATIONS_PAGINATION', {
			page: 0,
			limit: 0,
			totalItems: 0,
			totalPages: 0,
		});
	},
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
