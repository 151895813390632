import Vue from 'vue';
import Vuex from 'vuex';
// MODULES
import security from './modules/security';
import auth from './modules/auth';
import user from './modules/user';
import personal from './modules/personal';
import course from './modules/course';
import area from './modules/area';
import classification from './modules/classification';
import subtype from './modules/subtype';
import asset from './modules/assets';
import assetType from './modules/assets-type';
import assetHistory from './modules/assets-history';
import assetUnsubscribe from './modules/assets-unsubscribe';
import spare from './modules/spares';
import spareType from './modules/spares-type';
import spareHistory from './modules/spares-history';
import spareUnsubscribe from './modules/spares-unsubscribe';
import ticketAttention from './modules/ticket-attention';
import ticket from './modules/ticket';
import incident from './modules/incident';
import ticketComment from './modules/ticket-comment';
import ticketRating from './modules/ticket-rating';
import rating from './modules/rating';
import transfer from './modules/transfer';
import spareAssign from './modules/spare-assign';
import assetTransfer from './modules/assets-transfer';
import assetMaintenance from './modules/assets-maintenances';
import assetAssign from './modules/assets-assign';
import ratingType from './modules/rating-type';
import assetAssignment from './modules/assets-assignment';

Vue.use(Vuex);

export default new Vuex.Store({
	// TODO: strict: true,
	state: {},
	mutations: {},
	actions: {},
	modules: {
		security,
		auth,
		user,
		personal,
		course,
		area,
		classification,
		subtype,
		asset,
		assetType,
		assetHistory,
		assetUnsubscribe,
		spare,
		spareType,
		spareHistory,
		spareUnsubscribe,
		ticketAttention,
		ticket,
		incident,
		ticketComment,
		ticketRating,
		rating,
		transfer,
		spareAssign,
		assetTransfer,
		assetMaintenance,
		assetAssign,
		ratingType,
		assetAssignment
	},
});
