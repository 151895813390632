import { http } from '@/store/api/http-common';

const saveAsset = async (params) => {
	try {
		const { data } = await http.post('assets', params);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const saveAssetMultiple = async (params) => {
	try {
		const { data } = await http.post('assets/multiple-create', params);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

//
const getAssetById = async (id) => {
	try {
		const { data } = await http.get(`assets/${id}`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const getSparesByAsset = async (id) => {
	try {
		const { data } = await http.get(`assets/get-assigned-spares/${id}`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const getExportAsset = async (params) => {
	try {
		// const { data } = await http.get('assets', { params });
		// return data || null;

		const response = await http.get('assets/export', { params, responseType: 'blob' });
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', 'Reporte_activos.xlsx');
		document.body.appendChild(link);
		link.click();

	} catch (error) {
		return Promise.reject(error);
	}
};

const downloadAssetFormat = async () => {
	try {
		const response = await http.get('assets/format/download', {
			responseType: 'blob',
		});
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', 'FormatDeCargaMasivaActivo.xlsx');
		document.body.appendChild(link);
		link.click();
	} catch (error) {
		return Promise.reject(error);
	}
};
//

const getAsset = async (params) => {
	try {
		const { data } = await http.get('assets', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const updateAsset = async (id, params) => {
	try {
		const { data } = await http.put(`assets/${id}`, params);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const cancelAsset = async (id, params) => {
	try {
		const { data } = await http.put(`assets/unsubscribe-asset/${id}`, params);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const reestablishAsset = async (id) => {
	try {
		const { data } = await http.put(`assets/reestablish-asset/${id}`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const deleteAsset = async (id) => {
	try {
		const { data } = await http.delete(`assets/${id}`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const getAssetByUser = async (payload) => {
	try {
		const { data } = await http.post(`assets/get-my-assets`, payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const enableAsset = async (assetId) => {
	try {
		const { data } = await http.put(`assets/enable-asset/${assetId}`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

export {
	saveAsset,
	saveAssetMultiple,
	getAsset,
	updateAsset,
	deleteAsset,
	getAssetById,
	cancelAsset,
	reestablishAsset,
	getSparesByAsset,
	getAssetByUser,
	downloadAssetFormat,
	enableAsset,
	getExportAsset
};
